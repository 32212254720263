import React, { useEffect, useState } from "react";

import "./Login.css";
import Api from "./../../data/api";

export default function Login({ isPT }) {
  const [connections, setConnections] = useState(null);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedPassword, setSelectedPassword] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [testConnectionResult, setTestConnectionResult] = useState(null);
  const [enDate, setEnDate] = useState(null);
  const [ptDate, setPtDate] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const [loadingTest, setLoadingTest] = useState(false);
  const [errorTest, setErrorTest] = useState(false);

  useEffect(() => {
    if (connections === null) {
      getConnectionList();
      var today = new Date();
      let month = today.getMonth() + 1;
      let day = today.getDate();

      console.log(month, month < 10, day);
      var date =
        today.getFullYear() +
        "-" +
        (month < 10 ? `0${month}` : month) +
        "-" +
        (day < 10 ? `0${day}` : day);
      setCurrentDate(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedConnection !== null) {
      testConnection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConnection]);

  function testConnection() {
    setLoadingTest(true);
    Api.testConnection(`System/TestConnection`, selectedConnection)
      .then((result) => {
        setTestConnectionResult(result);
        var options = {
          weekday: "short",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        var today = new Date(result.HotelDateFixedFormat);

        setEnDate(
          today.toLocaleDateString("en-US", options).charAt(0).toUpperCase() +
            today.toLocaleDateString("en-US", options).replace(".", "").slice(1)
        );
        setPtDate(
          today
            .toLocaleDateString("pt-BR", options)
            .replace(".", "")
            .charAt(0)
            .toUpperCase() +
            today.toLocaleDateString("pt-BR", options).replace(".", "").slice(1)
        );
        setLoadingTest(false);
        setErrorTest(false);
      })
      .catch((error) => {
        setErrorTest(true);
        setLoadingTest(false);
        setTestConnectionResult(null);
      });
  }

  function getConnectionList() {
    Api.getData(`System/ConnectionList`)
      .then((result) => {
        setConnections(result);

        if (localStorage.getItem("lastConnectionV10Dashboard") === null) {
          localStorage.setItem(
            "lastConnectionV10Dashboard",
            result[0].ConnectionName
          );
        }

        setSelectedConnection(
          localStorage.getItem("lastConnectionV10Dashboard") !== null
            ? localStorage.getItem("lastConnectionV10Dashboard")
            : connections[0].ConnectionName
        );
      })
      .catch((error) => {
        /*  setErrors(true); */
      });
  }

  function onFinish(event) {
    setShowMessage(true);
    event.preventDefault();
    if (selectedUser.length > 0 && selectedPassword.length > 0) {
      setShowMessage(false);
      setLoading(true);
      Api.loginAuth(selectedConnection, selectedUser, selectedPassword)
        .then((result) => {
          console.log(result);
          if (result.success === true) {
            let targetUrl = Api.getQueryVariable("ReturnUrl");
            window.location.href =
              targetUrl !== "" ? targetUrl : `${window.location.origin}`;
          } else {
            console.log("here");
            setShowMessage(true);
            setErrorMessage(result.message);
            setSelectedPassword("");
            const input = document.getElementById("pw");
            input.focus();
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setShowMessage(true);
          const input = document.getElementById("pw");
          input.focus();
          setSelectedPassword("");
        });
    } else {
      setShowMessage(true);
      setSelectedUser("");
      setSelectedPassword("");
    }
  }

  function handleChangeConnection(e) {
    setSelectedConnection(e.target.value);
    localStorage.setItem("lastConnectionV10Dashboard", e.target.value);
  }

  function handleChangeName(e) {
    setSelectedUser(e.target.value);
  }

  function handleChangePassword(e) {
    setSelectedPassword(e.target.value);
  }

  return (
    <div
      className="login-wrapper"
      style={{ backgroundImage: `url("images/login_background.jpg")` }}
    >
      <div className="login-layout">
        <img
          className="img-hotel-logo"
          alt="img-hotel-logo"
          src="images/logo_hotel.png"
        ></img>
        {connections !== null && loading !== true ? (
          <form className="login-form" onSubmit={onFinish}>
            <label>
              {isPT ? "Propriedade" : "Property"}
              <div className="select-box">
                {selectedConnection !== null && (
                  <select
                    value={selectedConnection}
                    onChange={handleChangeConnection}
                  >
                    {connections.map((c, index) => {
                      return (
                        <option key={index} value={c.ConnectionName}>
                          {c.Description}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>
            </label>
            <label>
              {isPT ? "Utilizador" : "Username"}
              <input
                autoFocus
                placeholder={
                  isPT ? "Introduza o seu utilizador" : "Type your username"
                }
                type="text"
                value={selectedUser}
                onChange={handleChangeName}
              />
            </label>
            {showMessage ? (
              selectedUser.length > 0 ? (
                ""
              ) : (
                <span className="login-form-fields-error">
                  {isPT
                    ? "Por favor, introduza o seu nome de utilizador"
                    : "Please type your username"}
                </span>
              )
            ) : (
              ""
            )}

            <label>
              {isPT ? "Password" : "Password"}
              <input
                placeholder={
                  isPT ? "Introduza a sua password" : "Type your password"
                }
                type="password"
                id="pw"
                value={selectedPassword}
                onChange={handleChangePassword}
              />
            </label>
            {showMessage ? (
              selectedPassword.length > 0 ? (
                ""
              ) : (
                <span className="login-form-fields-error">
                  {isPT
                    ? "Por favor, introduza a sua password"
                    : "Please type your password"}
                </span>
              )
            ) : (
              ""
            )}

            {errorMessage !== null && (
              <div className="error-message-info">{errorMessage}</div>
            )}

            <input
              className={`${
                errorTest === false ? "input-submit" : "input-submit-block"
              }`}
              disabled={errorTest === false ? false : true}
              type="submit"
              value={isPT ? "Iniciar sessão" : "Login"}
            />
          </form>
        ) : (
          <img
            className="loading-gif"
            alt="loading-gif"
            src="images/spinning_loading.gif"
          ></img>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minHeight: 110,
          }}
        >
          {loadingTest ? (
            <img
              className="loading-gif"
              alt="loading"
              src="images/spinning_loading.gif"
            ></img>
          ) : (
            <>
              <img
                alt="host-logo"
                className="img-host-logo"
                /* src={logoHost} */ src="images/logo_host.png"
              ></img>

              {testConnectionResult !== null ? (
                <>
                  <span
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: 500,
                      fontSize: 14,
                      flexDirection: "column",
                    }}
                  >
                    {testConnectionResult.PmsVersion.Version.split(".")[3]}
                    <span
                      style={{
                        margin: "10px 0 5px 0",
                        fontWeight: 300,
                        padding: "5px 10px",
                        borderRadius: "5px",
                        background:
                          currentDate !==
                            testConnectionResult.HotelDateFixedFormat &&
                          "#cdd4db",
                        color:
                          currentDate !==
                            testConnectionResult.HotelDateFixedFormat &&
                          "white",
                      }}
                    >
                      {isPT ? ptDate : enDate}
                    </span>
                    {testConnectionResult.IsTrain && (
                      <span
                        style={{
                          fontWeight: 300,
                          color: "#5c89ff",
                        }}
                      >
                        {isPT ? "Modo treino ativo" : "Active training mode"}
                      </span>
                    )}
                  </span>

                  <div
                    style={{
                      fontSize: 9,
                      color: "#8894a1",
                      position: "absolute",
                      bottom: 10,
                      right: 10,
                    }}
                  >
                    {testConnectionResult.showDbInfo ? (
                      <span>{`${testConnectionResult.ServerAlias} ; ${testConnectionResult.DatabaseName} ${testConnectionResult.PlatformUid}`}</span>
                    ) : (
                      <span>{testConnectionResult.PlatformUid}</span>
                    )}
                  </div>
                </>
              ) : (
                <span
                  className="login-form-fields-error"
                  style={{
                    marginTop: 20,
                    fontSize: 12,
                    padding: "5px 10px",
                  }}
                >
                  {isPT
                    ? "Ocorreu um erro, ligação inválida"
                    : "An error occured, invalid connection"}
                </span>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
