import { useEffect, useState } from "react";
import "./App.css";
import Login from "./components/Login/Login";

function App() {
  const [isPT, setIsPT] = useState(null);

  useEffect(() => {
    setIsPT(window.navigator.language === "pt-PT");
  }, []);

  return <div>{isPT !== null ? <Login isPT={isPT}/> : ""}</div>;
}

export default App;
